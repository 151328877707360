<template>
    <main class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3">
                <div class="container">
                    <div class="grid mb-4 card nice-shadow-6  align-items-center">
                        <div class="col ">
                            <div class="">
                                <div class="flex align-items-center ">
                                    <div class="mr-3">
                                        <Button @click="$router.go(-1)" label="" icon="pi pi-arrow-left"
                                            class="p-button-sm p-button-rounded px-4" />
                                    </div>
                                    <div v-if="item">
                                        <div class="text-2xl text-primary font-bold">{{ $utils.upper(item.room_type) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section ">
            <div class="container">
                <div class="grid ">
                    <div class="col comp-grid">
                        <div>
                            <div style="min-height:100px">
                                <template v-if="pageReady">
                                    <div class="mb-3 grid justify-content-start">
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center p-3">
                                                <div class="col font-bold">
                                                    <image-viewer image-size="large" image-preview-size=""
                                                        :src="item.images" width="80px" height="80px" :num-display="10">
                                                    </image-viewer>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-12 md:col-4">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Room Type: </div>
                                                <div class="col font-bold">{{ $utils.upper(item.room_type) }}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-4">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Price (GHS): </div>
                                                <div class="col font-bold">{{ $utils.formatNumber(item.price, 2) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-4">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Booking Fee (GHS): </div>
                                                <div class="col font-bold">{{ $utils.formatNumber(item.booking_fee, 2)
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-4">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Available Beds: </div>
                                                <div class="col font-bold">{{ item.available_slots }}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-4">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Booked Beds: </div>
                                                <div class="col font-bold">{{ item.booked_slots }}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-4">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Date Created: </div>
                                                <div class="col font-bold">{{ $utils.humanDatetime(item.date_created) }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="item.booking_status == 'Pending'" class="flex  justify-content-center">
                                        <Button
                                            @click="app.openPageDialog({ page: 'info/terms', url: `/info/terms/app`, persistent: true, closeBtn: true, pageData: item })"
                                            label="Book Room" class="text-2xl px-5" size="large" />
                                    </div>
                                </template>
                                <template v-if="loading">
                                    <div class="p-3 text-center">
                                        <ProgressSpinner style="width:50px;height:50px" />
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>
<script setup>
    import { toRefs, onMounted } from 'vue';
    import { utils } from '@/utils';
    import { useApp } from '@/composables/app.js';
    import { useViewPage } from '@/composables/viewpage.js';
    const props = defineProps({
        id: [String, Number],
        primaryKey: {
            type: String,
            default: 'room_id',
        },
        pageName: {
            type: String,
            default: 'rooms',
        },
        routeName: {
            type: String,
            default: 'roomsdetails',
        },
        apiPath: {
            type: String,
            default: 'rooms/details',
        },
        editButton: {
            type: Boolean,
            default: true,
        },
        deleteButton: {
            type: Boolean,
            default: true,
        },
        exportButton: {
            type: Boolean,
            default: true,
        },
        titleBeforeDelete: {
            type: String,
            default: "Delete record",
        },
        msgBeforeDelete: {
            type: String,
            default: "Are you sure you want to delete this record?",
        },
        msgAfterDelete: {
            type: String,
            default: "Record deleted successfully",
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
        showFooter: {
            type: Boolean,
            default: true,
        },
        isSubPage: {
            type: Boolean,
            default: false,
        }
    });
    const app = useApp(props);
    const page = useViewPage(props); // page hook
    const { item, loading, pageReady } = toRefs(page.state);
    const { apiUrl, currentRecord } = page.computedProps
    const { load, deleteItem, exportPage, moveToNextRecord, moveToPreviousRecord } = page.methods;
    onMounted(() => {
        const pageTitle = "Room Details";
        app.setPageTitle(props.routeName, pageTitle); // set browser page title
        load();
    });
</script>
<style scoped></style>